import { useEffect } from "react";

export enum Page {
  Dashboard = "dashboard",
  App = "app",
}
export const useFavicon = (page?: Page) => {
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    switch (page) {
      case Page.Dashboard:
        (favicon as any).href = "dashboard-favicon.png";
        break;
      default:
        (favicon as any).href = "app-favicon.png";
    }
    return () => {
      const favicon = document.getElementById("favicon");
      (favicon as any).href = "app-favicon.png";
    };
  }, [page]);
};
