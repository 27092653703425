import { AiOutlineClose } from "react-icons/ai";
import { BluTipQuestion, Bullet, SetQuestionType } from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
interface Props {
  question: Partial<BluTipQuestion>;
  setQuestion: SetQuestionType<BluTipQuestion>;
}

const MultiBlueTipForm = ({ question, setQuestion }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        bullet: "",
        text: "",
        headline: "",
        id: (p?.bullets?.length || 0) + 1,
      };
      if (p.bullets) {
        p.bullets.push(val);
      } else {
        p.bullets = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.bullets!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ title: "Bullet", onAdd }}>
      {(question.bullets || []).map((bullet, i) => (
        <div key={i} className="flex gap-3 items-center mb-10">
          <div>
            {["bullet", "headline", "text"].map((key) => (
              <input
                type="text"
                key={key}
                className={
                  "input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
                }
                value={bullet[key as keyof Bullet]}
                placeholder={`Enter bullet number ${i + 1} ${key}`}
                onChange={(e) => {
                  setQuestion((p) => {
                    // @ts-ignore
                    (p.bullets![i] as Bullet)[key as keyof Bullet] =
                      e.target.value;
                  });
                }}
              />
            ))}
          </div>
          <div
            className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
            onClick={() => onRemove(i)}
          >
            <AiOutlineClose color="red" />
          </div>
        </div>
      ))}
    </DashboardFormWrapper>
  );
};

export default MultiBlueTipForm;
