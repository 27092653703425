import { ReactNode } from "react";

const MediaWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
}) => {
  return (
    <div
      className="
    w-full 
    relative
    px-4"
    >
      <div
        className="     
      w-[98%]
      md:w-8/12
      lg:w-4/12
      mx-auto"
      >
        {children}
      </div>
    </div>
  );
};

export default MediaWrapper;
