import { AiOutlineClose } from "react-icons/ai";
import uuid from "react-uuid";
import { BlueTipDotsListQuestion, IGraph, SetQuestionType } from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
import FormInput from "../FormInput";
import RectangleButton from "../../RectangleButton";
interface Props {
  question: Partial<BlueTipDotsListQuestion>;
  setQuestion: SetQuestionType<BlueTipDotsListQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

const BlueTipDotsListQuestionForm = ({ question, setQuestion }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        title: "",
        description: "",
        payload: [],
      };
      if (p.sections) {
        p.sections.push(val);
      } else {
        p.sections = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.sections!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Section" }}>
      <>
        {(question.sections || []).map((section, i) => (
          <div key={i} className="flex gap-3 items-center mb-10">
            <div>
              <FormInput
                value={section?.title}
                placeholder={`Enter section number ${i + 1} title`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.sections![i]!.title = e.target.value;
                  });
                }}
              />
              <FormInput
                value={section?.description}
                placeholder={`Enter section number ${i + 1} description`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.sections![i]!.description = e.target.value;
                  });
                }}
              />
              <div>
                {section.payload.map((c, idx) => (
                  <>
                    <div className="flex items-center">
                      <div
                        className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit"
                        onClick={() =>
                          setQuestion((q) => {
                            q.sections![i].payload.splice(idx, 1);
                          })
                        }
                      >
                        <AiOutlineClose color="red" />
                      </div>
                      <FormInput
                        key={idx}
                        value={c.title}
                        placeholder={`Enter title number ${idx + 1} text`}
                        onChange={(e) =>
                          setQuestion((q) => {
                            q.sections![i].payload![idx]!.title =
                              e.target.value;
                          })
                        }
                      />
                    </div>
                    <div>
                      {c.dots.map((dot, dotIdx) => (
                        <div key={`${dot.id}-dot`}>
                          <div className="flex items-center">
                            <div
                              className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit"
                              onClick={() =>
                                setQuestion((q) => {
                                  q.sections![i].payload![idx].dots.splice(
                                    dotIdx,
                                    1
                                  );
                                })
                              }
                            >
                              <AiOutlineClose color="red" />
                            </div>
                            <FormInput
                              value={dot.text}
                              placeholder={`Enter dot number ${
                                dotIdx + 1
                              } text`}
                              onChange={(e) =>
                                setQuestion((q) => {
                                  q.sections![i].payload![idx].dots[
                                    dotIdx
                                  ].text = e.target.value;
                                })
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <RectangleButton
                      onClick={() =>
                        setQuestion((q) => {
                          q.sections![i].payload[idx].dots.push({
                            text: "",
                            id: uuid(),
                          });
                        })
                      }
                      title={`Add dot`}
                      className="w-fit mx-auto my-10"
                    />
                  </>
                ))}
              </div>
              <RectangleButton
                onClick={() =>
                  setQuestion((q) => {
                    q.sections![i].payload.push({ title: "", dots: [] });
                  })
                }
                title={`Add sub-section`}
                className="w-fit mx-auto my-10"
              />
            </div>
            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
              onClick={() => onRemove(i)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        ))}
      </>
    </DashboardFormWrapper>
  );
};
export default BlueTipDotsListQuestionForm;
