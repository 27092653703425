import uuid from "react-uuid";
import { ID_KEY } from "./consts";

export const getId = () => {
  const id = localStorage.getItem(ID_KEY);
  return id ? id : setId();
};

export const setId = () => {
  const id = uuid();
  localStorage.setItem(ID_KEY, id);
  return id;
};
