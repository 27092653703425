interface Props {
  steps: number;
  currStep: number;
}

const ProgressBar = ({ steps, currStep }: Props) => {
  return (
    <div className="flex justify-center select-none">
      <div className="w-full px-4 flex justify-center text-gray-400 py-4">
        {currStep}/{steps}
      </div>
    </div>
  );
};

export default ProgressBar;
