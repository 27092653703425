import { AiOutlineClose } from "react-icons/ai";
import { CheckBoxOptions, CheckBoxQuestion, SetQuestionType } from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";

interface Props {
  question: Partial<CheckBoxQuestion>;
  setQuestion: SetQuestionType<CheckBoxQuestion>;
}

const CheckBoxesForm = ({ question, setQuestion }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        edit: false,
        text: "",
        title: "",
        id: (p?.answers?.length || 0) + 1,
      };
      if (p.answers) {
        p.answers.push(val);
      } else {
        p.answers = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p?.answers?.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Category" }}>
      {(question.answers || []).map((answer, i) => (
        <div key={i} className="flex gap-3 items-center mb-10">
          <div>
            {["title", "text"].map((key) => (
              <input
                type="text"
                key={key}
                disabled={key === "text" && answer.edit}
                className="input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
                value={answer[key as keyof CheckBoxOptions] as string}
                placeholder={`Enter checkbox number ${i + 1} ${key}`}
                onChange={(e) => {
                  setQuestion((p: any) => {
                    p.answers[i][key] = e.target.value;
                  });
                }}
              />
            ))}
            <div
              onClick={() =>
                setQuestion((p: Partial<CheckBoxQuestion>) => {
                  const isEditing = p.answers![i].edit;
                  if (!isEditing) {
                    // @ts-ignore
                    delete p.answers![i]?.text;
                  } else {
                    p.answers![i].text = "";
                  }
                  p.answers![i].edit = !isEditing;
                })
              }
              className="m-1 bg-white rounded w-fit p-1 cursor-pointer"
            >
              Turn edit {answer.edit ? "off" : "on"}
            </div>
          </div>
          <div
            className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
            onClick={() => onRemove(i)}
          >
            <AiOutlineClose color="red" />
          </div>
        </div>
      ))}
    </DashboardFormWrapper>
  );
};

export default CheckBoxesForm;
