import { ReactNode, useEffect, useState } from "react";
import { shadowStyle } from "utils";
import { AiOutlineDown } from "react-icons/ai";
import { motion } from "framer-motion";
import If from "./If";
import { CustomCategory } from "types";

interface CustomCategoryWithIcon extends Omit<CustomCategory, "icon"> {
  icon: ReactNode | ReactNode[];
  bg: string;
}

interface Props {
  items: CustomCategoryWithIcon[];
  value: CustomCategoryWithIcon;
  setValue: (category: CustomCategoryWithIcon) => void;
  loading?: boolean;
}

const DropDown = ({ items, value, setValue, loading }: Props) => {
  const [motionCounter, setMotionCounter] = useState(0);
  useEffect(() => {
    if (loading) {
      setMotionCounter(0);
    }
  }, [loading]);
  const shown = motionCounter % 2 !== 0;
  return (
    <>
      <div
        style={shadowStyle}
        onClick={() => {
          if (!loading) {
            setMotionCounter((p) => p + 1);
          }
        }}
        className={`w-full relative m-auto my-10 cursor-pointer p-1  border bg-white rounded-box ${
          loading && "cursor-not-allowed"
        }`}
      >
        <div className="z-50   flex gap-3 h-24 items-center justify-between">
          <div className="flex w-full items-center">
            <div>
              <div
                style={{ backgroundColor: value.bg }}
                className={`rounded-full w-16 min-h-16 mx-3 flex items-center justify-center `}
              >
                {value.icon}
              </div>
            </div>
            <div className="w-7/12">
              <h2 className="select-none overflow-hidden text-ellipsis text-primary-blue p-1 text-md md:text-xl">
                {value.title}
              </h2>
            </div>
          </div>
          <motion.div
            animate={{
              transform: `rotate(${!shown ? 0 : 180}deg)`,
              transition: {
                ease: "linear",
              },
            }}
            className="mr-5"
          >
            <AiOutlineDown />
          </motion.div>
        </div>
        <If cond={shown && !loading} delay={100}>
          <motion.div
            initial={{
              opacity: 0,
              y: -100,
            }}
            animate={{
              y: shown ? 3 : -100,
              opacity: shown ? 1 : -3,
              transition: {
                ease: "linear",
              },
            }}
            className={`z-30 py-5 bg-white w-full left-0 absolute mt-3 
           p-1  border rounded-box`}
          >
            {items
              ?.filter((item) => item.title !== value.title)
              ?.map(({ title, icon, bg, id }, i) => (
                <div key={id}>
                  <div
                    onClick={() => {
                      setValue({ title, icon, bg, id });
                    }}
                    className="p-1 py-0   text-ellipsis overflow-hidden "
                  >
                    <div className="flex items-center">
                      <div
                        style={{ backgroundColor: bg }}
                        className={`rounded-full w-16 min-w-[4rem] h-16  mx-3 flex items-center justify-center `}
                      >
                        {icon}
                      </div>
                      <span className="text-primary-blue text-md md:text-xl p-1">
                        {title}
                      </span>
                    </div>
                  </div>
                  <If key={`${id}-divider`} cond={i < items.length - 2}>
                    <div className="divider w-11/12 mx-auto"></div>
                  </If>
                </div>
              ))}
          </motion.div>
        </If>
      </div>
    </>
  );
};

export default DropDown;
