import { AiOutlineRight } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

interface Props {
  onClick: () => void;
  className?: string;
  loading?: boolean;
}
const NextButton = ({ onClick, className = "", loading }: Props) => {
  return (
    <div
      className={`
    bg-primary-purple
    rounded-full
    cursor-pointer
    w-[48px]
    h-[48px]
    flex
    justify-center
    items-center
    ${className}
    ${loading && "cursor-not-allowed"}
    ${!loading && "hover:bg-primary-blue"}
    `}
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
      style={{
        boxShadow: "0px 0px 34px rgba(129, 144, 192, 0.37)",
      }}
    >
      {loading ? (
        <ClipLoader color={"white"} />
      ) : (
        <AiOutlineRight
          className="
      text-white"
          size={22}
        />
      )}
    </div>
  );
};

export default NextButton;
