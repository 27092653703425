import uuid from "react-uuid";
import { CUSTOM_QUESTIONS_ICONS } from "../CustomQuestion/CustomQuestionUtils";

interface Props {
  name: string;
  currIcon: string;
  idx: number;
  onChange: (icon: string) => void;
}
const IconFormPicker = ({ name, currIcon, idx, onChange }: Props) => {
  return (
    <>
      <div className="text-primary-blue text-xl">
        Choose {name} number {idx + 1} icon:
      </div>
      <div>
        {Object.keys(CUSTOM_QUESTIONS_ICONS).map((icon) => (
          <div
            className={`rounded p-1 cursor-pointer ${
              currIcon === icon && "bg-slate-700 text-white"
            }`}
            onClick={() => onChange(icon)}
            key={uuid()}
          >
            {icon}
          </div>
        ))}
      </div>
    </>
  );
};

export default IconFormPicker;
