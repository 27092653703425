import { Answer, ChatPresentationQuestion } from "types";
import Bullet from "../Bullet";
import Message from "../Message";
import NextButton from "../NextButton";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: ChatPresentationQuestion;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
function ChatPresentation({ question, onAnswer, loading }: Props) {
  return (
    <QuestionWrapper
      {...{ headline: question.headline, description: question.description }}
    >
      <>
        {question?.sections?.map((section) => (
          <Bullet
            {...{
              bullet: {
                bullet: section.title,
                headline: "",
                text: section.description,
                id: Math.random(), //TODO remove from type
              },
            }}
          >
            <div className="mt-3">
              {section.chat.map(({ from, text }) => (
                <Message {...{ from, text }} />
              ))}
            </div>
          </Bullet>
        ))}
        <NextButton
          className="ml-auto mt-10"
          loading={loading}
          onClick={() => {
            onAnswer(true);
          }}
        />
      </>
    </QuestionWrapper>
  );
}

export default ChatPresentation;
