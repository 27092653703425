import uuid from "react-uuid";

export const InjectedBoldText = ({ text }: { text: string }) =>
  text ? (
    <>
      {text?.split("[b]")?.map((s) =>
        s?.startsWith("*") ? (
          <span key={uuid()} className="font-heebo-bold">
            {s.substring(1, s.length)}
          </span>
        ) : (
          s
        )
      )}
    </>
  ) : (
    <></>
  );
