import {
  MultiSliderQuestion,
  QuestionType,
  SetQuestionType,
  SliderQuestion,
} from "types";
import { AiOutlineClose } from "react-icons/ai";
import { DashboardFormWrapper } from "../DashboardFormWrapper";

interface Props {
  question: Partial<SliderQuestion | MultiSliderQuestion>;
  setQuestion: SetQuestionType<SliderQuestion | MultiSliderQuestion>;
  sliderIdx?: number;
}
const SliderTipForm = ({ setQuestion, question, sliderIdx }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      switch (p.type) {
        case QuestionType.Slider:
          if (!(p as SliderQuestion).tips) {
            (p as SliderQuestion).tips = {};
          }
          (p as SliderQuestion).tips!["0-10"] = "";
          return;
        default:
          if (!(p as MultiSliderQuestion).sliders[sliderIdx as number].tips) {
            (p as MultiSliderQuestion).sliders[sliderIdx as number].tips = {};
          }
          (p as MultiSliderQuestion).sliders[sliderIdx as number].tips![
            "0-10"
          ] = "";
          return;
      }
    });
  };
  const onRemove = (key: string) => {
    setQuestion((p) => {
      switch (p.type) {
        case QuestionType.Slider:
          delete (p as SliderQuestion).tips![key];
          if (!Object.keys((p as SliderQuestion).tips!).length) {
            delete (p as SliderQuestion).tips;
          }
          return;
        default:
          delete (p as MultiSliderQuestion).sliders[sliderIdx as number].tips![
            key
          ];
          if (
            !Object.keys(
              (p as MultiSliderQuestion).sliders[sliderIdx as number].tips!
            ).length
          ) {
            delete (p as MultiSliderQuestion).sliders[sliderIdx as number].tips;
          }
          return;
      }
    });
  };
  const changeKey = (newKey: string, tipKey: string) => {
    setQuestion((p) => {
      switch (p.type) {
        case QuestionType.Slider:
          (p as SliderQuestion).tips![newKey] = (p as SliderQuestion).tips![
            tipKey
          ];
          delete (p as SliderQuestion).tips![tipKey];
          return;
        default:
          (p as MultiSliderQuestion).sliders[sliderIdx as number].tips![
            newKey
          ] = (p as MultiSliderQuestion).sliders[sliderIdx as number].tips![
            tipKey
          ];
          delete (p as MultiSliderQuestion).sliders[sliderIdx as number].tips![
            tipKey
          ];
          return;
      }
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Tip" }}>
      {Object.keys(
        (question.type === QuestionType.Slider
          ? (question as SliderQuestion)?.tips
          : (question as MultiSliderQuestion).sliders[sliderIdx as number]
              ?.tips) || {}
      ).map((tipKey, i) => {
        const [minVal, maxVal] = tipKey.split("-");
        return (
          <div key={i} className="items-center flex  ">
            <div>
              <div className="flex  w-fit">
                <input
                  type="number"
                  className="w-8"
                  value={+minVal}
                  onChange={(e) => {
                    changeKey(`${e.target.value ?? 0}-${maxVal}`, tipKey);
                  }}
                />
                <div className="w-3 translate-y-2 h-[2px] bg-black mr-3" />
                <input
                  type="number"
                  className="w-8"
                  value={+maxVal}
                  onChange={(e) => {
                    changeKey(`${minVal}-${e.target.value ?? 0}`, tipKey);
                  }}
                />
              </div>
              <div className=" w-fit my-2">
                <input
                  type="text"
                  placeholder="Enter tip:"
                  value={
                    question.type === QuestionType.Slider
                      ? (question as SliderQuestion).tips![tipKey]
                      : (question as MultiSliderQuestion).sliders[
                          sliderIdx as number
                        ].tips![tipKey]
                  }
                  onChange={(e) => {
                    setQuestion((p) => {
                      switch (p.type) {
                        case QuestionType.Slider:
                          (p as SliderQuestion).tips![tipKey] = e.target.value;
                          return;
                        default:
                          (p as MultiSliderQuestion).sliders[
                            sliderIdx as number
                          ].tips![tipKey] = e.target.value;
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit mx-auto"
              onClick={() => onRemove(tipKey)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        );
      })}
    </DashboardFormWrapper>
  );
};

export default SliderTipForm;
