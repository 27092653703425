import { isDashboardInDevMode } from "bin";
import { NextQuestion, SetQuestionType } from "types";
import If from "../../If";
import { NEXT_PAGE_IMGS } from "../../Next/nextUtils";
interface Props {
  question: Partial<NextQuestion>;
  setQuestion: SetQuestionType<NextQuestion>;
}

const NextPageForm = ({ question, setQuestion }: Props) => {
  const onChoose = (item: string) => {
    setQuestion((p) => {
      p.image = p.image === item ? undefined : item;
    });
  };
  return (
    <If cond={isDashboardInDevMode}>
      <div>
        Choose image:
        {Object.keys(NEXT_PAGE_IMGS).map((key) => (
          <div
            onClick={() => onChoose(key)}
            className={`
          w-fit
          p-2 m-2 hover:bg-slate-300 cursor-pointer mt-4
          ${question.image === key && "bg-slate-200"}`}
            key={key}
          >
            {key}
          </div>
        ))}
      </div>
    </If>
  );
};

export default NextPageForm;
