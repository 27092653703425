import { useEffect, useRef } from "react";
import Container from "components/Container";
import { useChat, useFavicon } from "hooks";
import QuestionsViewer from "components/QuestionsViewer";
import AppLoader from "components/AppLoader";

const Questions = () => {
  const { loading, error, question, onAnswer, deleteLastAnswer } = useChat();
  const scrollRef = useRef<HTMLDivElement>(null);
  useFavicon();

  useEffect(() => {
    if (!loading) {
      scrollRef.current?.scrollTo({ top: 0 });
    }
  }, [loading]);

  if (error) {
    return (
      <Container>
        <h2>Error...</h2>
      </Container>
    );
  }

  if (!question.type) {
    return <AppLoader />;
  }

  return (
    <Container>
      <QuestionsViewer
        containerClassName="h-full overflow-scroll pb-10"
        {...{ loading, onAnswer, scrollRef, question }}
        onHeaderBackButtonPress={deleteLastAnswer}
      />
    </Container>
  );
};
export default Questions;
