import {
  Answer,
  UserQuestionsListQuestion as UserQuestionsListQuestionType,
} from "types";
import { icons } from "utils";
import Accordion from "../Accordion";
import Button from "../Button";
import { CUSTOM_QUESTIONS_ICONS } from "../CustomQuestion/CustomQuestionUtils";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: UserQuestionsListQuestionType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}

const UserQuestionListQuestion = ({ question, onAnswer, loading }: Props) => {
  return (
    <QuestionWrapper
      {...{ headline: question.headline, description: question.description }}
    >
      <div>
        {question?.categories?.map((category) => {
          const icon = category?.icon;
          const iconData =
            CUSTOM_QUESTIONS_ICONS[icon as keyof typeof CUSTOM_QUESTIONS_ICONS];
          return (
            <Accordion
              title={category?.title}
              icon={iconData?.icon}
              iconBg={iconData?.bg}
              className="mb-5"
              key={category?.id}
            >
              <div className="flex flex-col gap-2 relative">
                {category?.data?.map(({ text, id }) => {
                  return (
                    <div className="select-none p-4 bg-gray-highlight rounded-[15px]">
                      {text}
                    </div>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
        <div>
          {question.actions?.map(({ text, step_id, type, leftIcon }) => (
            <Button
              {...{ loading }}
              key={type}
              icon={leftIcon && icons[leftIcon as keyof typeof icons]}
              className={`w-10/12 mx-auto my-5  flex justify-center items-center`}
              iconClassName={`${
                type === "edit_list" && "absolute ml-[40px] mr-auto"
              }`}
              secondary={type !== "edit_list"}
              title={text}
              onClick={() => {
                onAnswer({
                  action: type,
                  stepId: step_id as any,
                });
              }}
            />
          ))}
        </div>
      </div>
    </QuestionWrapper>
  );
};

export default UserQuestionListQuestion;
