import { Answer, InformationQuestion as InformationQuestionType } from "types";
import Button from "../Button";
import { CUSTOM_QUESTIONS_ICONS } from "../CustomQuestion/CustomQuestionUtils";
import IconCard from "../IconCard";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: InformationQuestionType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
const InformationQuestion = ({ question, onAnswer, loading }: Props) => {
  return (
    <QuestionWrapper
      {...{
        headline: question.headline,
        description: question.description,
      }}
    >
      <If cond={!!question.identification_description}>
        <div className="text-primary-blue font-heebo-medium text-center mb-10 mx-auto px-5 text-xl lg:text-2xl whitespace-pre-line break-words">
          {question.identification_description}
        </div>
      </If>
      <div>
        {question?.identifications?.map((identification, i) => {
          const data =
            CUSTOM_QUESTIONS_ICONS[
              identification.icon as keyof typeof CUSTOM_QUESTIONS_ICONS
            ];
          return (
            <IconCard
              key={i}
              {...{
                bg: data?.bg || "",
                icon: data?.icon || "",
                text: identification.text,
              }}
            />
          );
        })}
      </div>
      <Button
        className="px-6 w-fit min-w-[350px]  mx-auto mt-20"
        loading={loading}
        title={question.button_text}
        onClick={() => onAnswer(true)}
      />
    </QuestionWrapper>
  );
};

export default InformationQuestion;
