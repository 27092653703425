import { useEffect } from "react";
import {
  CommunicationType,
  IGraph,
  SetQuestionType,
  WhatsappStepLink,
} from "types";
import GraphPicker from "../../GraphPicker";

interface Props {
  question: Partial<WhatsappStepLink>;
  setQuestion: SetQuestionType<WhatsappStepLink>;
  graph: IGraph;
  loadingGraph: boolean;
}
function WhatsappStepLinkFrom({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) {
  useEffect(() => {
    setQuestion((p) => {
      p.communication_type = CommunicationType.Whatsapp;
    });
  }, [setQuestion]);
  return (
    <GraphPicker
      {...{
        graph,
        loading: loadingGraph,
        title: question.next_step_id || `Pick next step id`,
        onPick: (id) =>
          setQuestion((q) => {
            q.next_step_id = id;
          }),
      }}
    />
  );
}

export default WhatsappStepLinkFrom;
