import { AiOutlineClose } from "react-icons/ai";
import { InformationQuestion, SetQuestionType } from "types";
import { CUSTOM_QUESTIONS_ICONS } from "../../CustomQuestion/CustomQuestionUtils";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
interface Props {
  question: Partial<InformationQuestion>;
  setQuestion: SetQuestionType<InformationQuestion>;
}

const InformationForm = ({ question, setQuestion }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        text: "",
        icon: "",
      };
      if (p.identifications) {
        p.identifications.push(val);
      } else {
        p.identifications = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.identifications!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Identification" }}>
      {(question.identifications || []).map((identification, i) => (
        <div key={i} className="flex gap-3 items-center mb-10">
          <div>
            <input
              type="text"
              className="input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
              value={identification?.text}
              placeholder={`Enter identification number ${i + 1} text`}
              onChange={(e) => {
                setQuestion((p) => {
                  p.identifications![i]!.text = e.target.value;
                });
              }}
            />
            <div>
              {Object.keys(CUSTOM_QUESTIONS_ICONS).map((icon) => (
                <div
                  className={`rounded p-1 cursor-pointer ${
                    identification.icon === icon && "bg-slate-200"
                  }`}
                  onClick={() =>
                    setQuestion((p) => {
                      p.identifications![i].icon = icon;
                    })
                  }
                >
                  {icon}
                </div>
              ))}
            </div>
          </div>
          <div
            className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
            onClick={() => onRemove(i)}
          >
            <AiOutlineClose color="red" />
          </div>
        </div>
      ))}
    </DashboardFormWrapper>
  );
};
export default InformationForm;
