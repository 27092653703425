import uuid from "react-uuid";
import { Question } from "types";
import { mocks } from "utils";

interface Props {
  setQuestionViewMode: React.Dispatch<React.SetStateAction<Question>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ViewMenu = ({
  setQuestionViewMode,
  loading,
  setLoading,
}: Props) => {
  return (
    <>
      <div className=" gap-5 w-fit p-10 rounded text-primary-blue flex flex-col items-center justify-center">
        {mocks.map((question) => (
          <div
            className="cursor-pointer hover:bg-gray-200 px-8 py-4"
            key={uuid()}
            onClick={() => setQuestionViewMode(question as any)}
          >
            {question.type.split("_").join(" ")}
          </div>
        ))}
        <div
          onClick={() => setLoading((p) => !p)}
          className={`cursor-pointer hover:bg-gray-200 px-8 py-4`}
        >
          toggle loading
          <div
            className={`h-3 w-3 ml-2  ${
              loading ? "bg-green-500" : "bg-red-500"
            } rounded-full inline-block`}
          />
        </div>
      </div>
    </>
  );
};
