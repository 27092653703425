interface Props {
  from: string;
  text: string;
}

const Message = ({ from, text }: Props) => {
  const fromYou = from === "You";

  return (
    <div className={fromYou ? styles.wrapperYou : styles.wrapper}>
      <div className={fromYou ? styles.bubbleRight : styles.bubbleLeft}></div>
      <div className={fromYou ? styles.fromYou : styles.from}>{from}</div>
      {text}
    </div>
  );
};

const styles = {
  wrapper: "w-[90%] m-2 rounded-xl px-2.5 py-3.5 relative bg-[#F1F1FD]",
  wrapperYou:
    "w-[90%] m-2 rounded-xl px-2.5 py-3.5 relative ml-[10%] bg-[#E6EDFF]",
  bubbleLeft:
    "absolute left-[-10px] top-0 w-0 h-0 border-solid border-x-[10px] border-y-[35px] border-r-[#F1F1FD] border-t-[#F1F1FD] border-l-transparent border-b-transparent",
  bubbleRight:
    "absolute right-[-10px] top-0 w-0 h-0 border-solid border-x-[10px] border-y-[35px] border-r-transparent border-t-[#E6EDFF] border-l-[#E6EDFF] border-b-transparent",
  from: "text-primary-blue font-heebo-bold",
  fromYou: "text-[#687BB2] font-heebo-bold",
};

export default Message;
