import uuid from "react-uuid";
import { classes } from "pages/Dashboard/dashboardUtils";
import {
  Question,
  SetQuestionType,
  WebQuestionType,
  WhatsappQuestionType,
} from "types";
import Title from "../Title";
import { isDashboardInDevMode } from "bin";
import If from "components/If";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
}

export const QuestionTypePicker = ({ question, setQuestion }: Props) => {
  return (
    <>
      <Title headline="Choose question type:" />
      <div className={classes.wrap}>
        {Object.values(WebQuestionType).map((type) => (
          <div
            key={uuid()}
            className={`${classes.bullet} ${
              (question.type as unknown as WebQuestionType) === type &&
              "bg-slate-700 text-white"
            }`}
            onClick={() =>
              setQuestion(
                ({
                  header,
                  headline,
                  description,
                  step_id,
                  step_number,
                }: any) => ({
                  header,
                  headline,
                  description,
                  type,
                  step_id,
                  step_number,
                })
              )
            }
          >
            {type.split("_").join(" ")}
          </div>
        ))}
        <If cond={isDashboardInDevMode}>
          {Object.values(WhatsappQuestionType).map((type) => (
            <div
              key={uuid()}
              className={`${classes.bullet} ${
                (question.type as unknown as WhatsappQuestionType) === type
                  ? "bg-slate-700 text-white"
                  : "bg-green-200"
              }`}
              onClick={() =>
                setQuestion(
                  ({
                    header,
                    headline,
                    description,
                    step_id,
                    step_number,
                  }: any) => ({
                    header,
                    headline,
                    description,
                    type,
                    step_id,
                    step_number,
                  })
                )
              }
            >
              {type.split("_").join(" ")}
            </div>
          ))}
        </If>
      </div>
    </>
  );
};
