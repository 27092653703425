import { AiOutlineClose } from "react-icons/ai";
import { ChatPresentationQuestion, IGraph, SetQuestionType } from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
import FormInput from "../FormInput";
import RectangleButton from "../../RectangleButton";
interface Props {
  question: Partial<ChatPresentationQuestion>;
  setQuestion: SetQuestionType<ChatPresentationQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

const ChatPresentationForm = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        title: "",
        description: "",
        chat: [],
      };
      if (p.sections) {
        p.sections.push(val);
      } else {
        p.sections = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.sections!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Section" }}>
      <>
        {(question.sections || []).map((section, i) => (
          <div key={i} className="flex gap-3 items-center mb-10">
            <div>
              <FormInput
                value={section?.title}
                placeholder={`Enter section number ${i + 1} title`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.sections![i]!.title = e.target.value;
                  });
                }}
              />
              <FormInput
                value={section?.description}
                placeholder={`Enter section number ${i + 1} description`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.sections![i]!.description = e.target.value;
                  });
                }}
              />
              <div>
                {section.chat.map((c, idx) => (
                  <>
                    <FormInput
                      key={idx}
                      placeholder={`Enter message number ${idx + 1} from`}
                      value={c.from}
                      onChange={(e) =>
                        setQuestion((q) => {
                          q.sections![i].chat![idx]!.from = e.target.value;
                        })
                      }
                    />
                    <FormInput
                      key={idx}
                      value={c.text}
                      placeholder={`Enter message number ${idx + 1} text`}
                      onChange={(e) =>
                        setQuestion((q) => {
                          q.sections![i].chat![idx]!.text = e.target.value;
                        })
                      }
                    />

                    <div
                      className="hover:bg-red-200 rounded-full p-1 cursor-pointer w-fit"
                      onClick={() =>
                        setQuestion((q) => {
                          q.sections![i].chat.splice(idx, 1);
                        })
                      }
                    >
                      <AiOutlineClose color="red" />
                    </div>
                  </>
                ))}
              </div>
              <RectangleButton
                onClick={() =>
                  setQuestion((q) => {
                    q.sections![i].chat.push({ text: "", from: "" });
                  })
                }
                title={`Add Message`}
                className="w-fit mx-auto my-10"
              />
            </div>
            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
              onClick={() => onRemove(i)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        ))}
      </>
    </DashboardFormWrapper>
  );
};
export default ChatPresentationForm;
