interface Props {
  currStep: number;
  stepsAmount: number;
  className?: string;
}
const BubblesPagination = ({
  currStep,
  stepsAmount,
  className = "",
}: Props) => {
  return (
    <div className={`${className} flex flex-wrap gap-3 justify-center w-full`}>
      {new Array(stepsAmount).fill(null).map((step, idx) => (
        <div
          className={`${
            idx === currStep ? "bg-primary-blue" : "bg-[#AFBBCF]            "
          }
          rounded-full
        w-2
        h-2
          `}
          key={idx}
        ></div>
      ))}
    </div>
  );
};

export default BubblesPagination;
