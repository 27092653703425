import uuid from "react-uuid";
import { classes } from "pages/Dashboard/dashboardUtils";
import { HeaderType, Question, SetQuestionType } from "types";
import { HEADER_PICS } from "../Header/HeaderUtils";
import If from "../If";
import { QUESTIONS_PICS } from "../QuestionWrapper/QuestionWrapperUtils";
import Title from "../Title";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
}

export const QuestionHeaderPicker = ({ question, setQuestion }: Props) => {
  return (
    <>
      <Title headline="Choose header type:" />
      <div className={classes.wrap}>
        {Object.values(HeaderType).map((v) => (
          <div
            className={`${classes.bullet} ${
              question.header?.type === v && "bg-slate-700 text-white"
            }`}
            key={uuid()}
            onClick={() =>
              setQuestion((prev) => {
                prev.header = { type: v };
              })
            }
          >
            {v.split("_").join(" ")}
          </div>
        ))}
      </div>
      <If cond={question.header?.type === HeaderType.TitleImage}>
        <input
          type="text"
          placeholder="Enter header title:"
          className={classes.input}
          onChange={(e) => {
            setQuestion((prev) => {
              prev.header!.title = e.target.value;
            });
          }}
        />
      </If>
      <If cond={!!question.header}>
        <div className={classes.wrap}>
          {Object.keys(
            question.header?.type === HeaderType.TitleImage
              ? HEADER_PICS
              : QUESTIONS_PICS
          ).map((pic) => (
            <div
              key={uuid()}
              className={`${classes.bullet} ${
                question.header?.image === pic && "bg-slate-700 text-white"
              }`}
              onClick={() =>
                setQuestion((prev) => {
                  prev.header!.image = pic;
                })
              }
            >
              {pic}
            </div>
          ))}
        </div>
      </If>
    </>
  );
};
