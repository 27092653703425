import { IGraph, Maybe, Question } from "types";
import { logger } from "utils/Logger";
import network from "./network";

const BASE_URL = "/editor";
export const addStep = async (
  question: Question,
  isRoot?: boolean
): Promise<boolean> => {
  try {
    const { data } = await network.post(
      `${BASE_URL}/steps/create`,
      isRoot ? { ...question, node_type: "root" } : question
    );
    return data;
  } catch ({ message }) {
    logger.error(message);
    return false;
  }
};

export const getGraph = async (): Promise<IGraph | false> => {
  try {
    const { data } = await network.get(`${BASE_URL}/graph`);
    return data;
  } catch ({ message }) {
    logger.error(message);
    return false;
  }
};
export const getAllSteps = async (): Promise<Maybe<Question[]>> => {
  try {
    const { data } = await network.get(`${BASE_URL}/steps`);
    return data.steps as Question[];
  } catch ({ message }) {
    logger.error(message);
    return null;
  }
};

interface StepPayload {
  step_id: string;
  update: Partial<Question>;
}

export const updateStep = async (payload: StepPayload): Promise<boolean> => {
  try {
    const { data } = await network.post(`${BASE_URL}/steps/update`, payload);
    return data;
  } catch ({ message }) {
    logger.error(message);
    return false;
  }
};

export const reorderSteps = async (order: StepPayload[]): Promise<boolean> => {
  try {
    const { data } = await network.post(`${BASE_URL}/steps/reorder`, { order });
    return data;
  } catch ({ message }) {
    logger.error(message);
    return false;
  }
};

interface DeletePayload {
  success: boolean;
  step_id: string;
  error?: string;
}

export const deleteStep = async (stepId: string): Promise<boolean> => {
  try {
    const { data } = await network.delete<DeletePayload>(
      `${BASE_URL}/steps/${stepId}`
    );
    if (data.error) {
      throw new Error(data.error);
    }
    return data.success;
  } catch ({ message }) {
    logger.error(message);
    return false;
  }
};
