import { useEffect, useState } from "react";
import { CheckBoxAnswer, CheckBoxQuestion } from "types";
import CheckBox from "../CheckBox";
import NextButton from "../NextButton";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import produce from "immer";

interface CheckBoxAction {
  type: "text" | "check";
  data?: string;
}
interface Props {
  question: CheckBoxQuestion;
  onAnswer: (answer: CheckBoxAnswer[]) => void;
  loading: boolean;
}
const CheckBoxesQuestion = ({ question, onAnswer, loading }: Props) => {
  const { headline, description } = question;
  const [state, setState] = useState(
    question?.answers?.map((e) => ({ ...e, checked: false }))
  );

  useEffect(() => {
    setState(question?.answers?.map((e) => ({ ...e, checked: false })));
  }, [question.answers]);

  return (
    <QuestionWrapper {...{ headline, description }}>
      <>
        {state?.map((answer, i) => (
          <CheckBox
            loading={loading}
            edit={answer.edit}
            id={answer.id}
            text={answer.text}
            title={answer.title}
            checked={answer.checked}
            onChange={(action: CheckBoxAction) => {
              if (action.type === "check") {
                setState(
                  produce((p) => {
                    p[i].checked = !p[i].checked;
                  })
                );
              } else {
                setState(
                  produce((p) => {
                    p[i].text = action.data!;
                  })
                );
              }
            }}
            key={answer.id}
          />
        ))}
        <NextButton
          className="ml-auto mt-10"
          loading={loading}
          onClick={() => {
            onAnswer(
              Object.values([...state])
                .filter((data) => data.checked)
                .map(({ text, title, id }) => ({ text, title, id }))
            );
          }}
        />
      </>
    </QuestionWrapper>
  );
};

export default CheckBoxesQuestion;
