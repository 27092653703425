// enum Env {
//   Prod = "production",
//   Dev = "development",
//   Staging = "staging",
// }

// export const API_URL = "https://api-dev.epilog.world";
// eslint-disable-next-line
export const isDashboardInDevMode = !!eval(
  process.env.REACT_APP_SHOW_DASHBOARD_PAYLOAD || "true"
);
