export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

export const stringToColor = (stringInput: string) => {
  if (stringInput === "non-medication-P") {
    return "#857ffe";
  } else if (stringInput === "digital") {
    return "#e6b5ff";
  }
  let stringUniqueHash = stringInput.split("").reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  stringUniqueHash = Math.abs(stringUniqueHash);
  return `hsl(${((stringUniqueHash * 3) % 100) + 175}, ${
    ((stringUniqueHash * 2) % 17) + 84
  }%, ${((stringUniqueHash * 4) % 31) + 20}%)`;
};
