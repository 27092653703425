import { useState } from "react";
import { SliderQuestion as SliderQuestionType } from "types";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import NextButton from "../NextButton";
import Slider from "../Slider";
import { DEFAULT_SLIDER_STEP } from "utils";

interface Props {
  question: SliderQuestionType;
  onAnswer: (range: number) => void;
  loading?: boolean;
}

const SliderQuestion = ({
  onAnswer,
  question: {
    description,
    headline,
    min_value,
    max_value,
    min_range,
    max_range,
    header,
    mid_value,
    tips,
  },
  loading,
}: Props) => {
  const [value, setValue] = useState(DEFAULT_SLIDER_STEP);
  return (
    <>
      <QuestionWrapper {...{ description, headline, thumbnail: header }}>
        <Slider
          {...{
            min_range,
            max_range,
            min_value,
            max_value,
            mid_value,
            value,
            setValue,
            loading,
            tips,
          }}
        />
        <NextButton
          onClick={() => onAnswer(value)}
          className="ml-auto mt-10"
          loading={loading}
        />
      </QuestionWrapper>
    </>
  );
};

export default SliderQuestion;
