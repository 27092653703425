import { ReactNode } from "react";
import ReactModal from "react-modal";
// import { IoIosClose } from "react-icons/io";

ReactModal.setAppElement("#root");

interface Props {
  isOpen: boolean;
  setIsOpen?: (prev: boolean) => void;
  onClose?: () => void;
  children: ReactNode | ReactNode[];
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    padding: 16,
  },
};

const Modal = ({ isOpen, children }: Props) => {
  return (
    <ReactModal
      style={customStyles}
      isOpen={isOpen}
      overlayClassName="bg-[rgba(0,0,0,0.5)] z-[100] h-[100vh] w-[100vw] absolute top-0"
    >
      <div>
        {/* <div className="w-full flex justify-end">
          <IoIosClose
            size={45}
            color="#4059AD"
            onClick={() => {
              onClose && onClose();
              setIsOpen(false);
            }}
          />
        </div> */}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
