import {
  CommunicationType,
  IGraph,
  MultiQuestion,
  SetQuestionType,
} from "types";
import { AiOutlineClose } from "react-icons/ai";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
import { HEADER_PICS } from "../../Header/HeaderUtils";
import GraphPicker from "../../GraphPicker";
import If from "../../If";
import { useEffect } from "react";
import { isDashboardInDevMode } from "bin";
import Accordion from "components/Accordion";

interface Props {
  question: Partial<MultiQuestion>;
  setQuestion: SetQuestionType<MultiQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
  whatsappMode?: boolean;
}
const MultiChoiceFrom = ({
  setQuestion,
  question,
  graph,
  loadingGraph,
  whatsappMode,
}: Props) => {
  const onAdd = () => {
    if (whatsappMode && (question?.answers?.length || 0) > 2) return;
    setQuestion((p) => {
      const val = { text: "", id: (p?.answers?.length || 0) + 1 };
      if (p.answers) {
        p.answers.push(val);
      } else {
        p.answers = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.answers!.splice(i, 1);
    });
  };

  useEffect(() => {
    if (whatsappMode) {
      setQuestion((q) => {
        q.communication_type = CommunicationType.Whatsapp;
      });
    }
  }, [whatsappMode, setQuestion]);

  return (
    <DashboardFormWrapper {...{ onAdd, title: "Answer" }}>
      {(question.answers || []).map((answer, i) => (
        <div key={i} className="flex gap-3 items-center">
          <div>
            <input
              type="text"
              className="
        input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto
        "
              value={answer.text}
              placeholder={`Enter answer number ${i + 1}`}
              onChange={(e) => {
                setQuestion((p) => {
                  p.answers![i].text = e.target.value;
                });
              }}
            />
            <If cond={isDashboardInDevMode}>
              <div className="flex justify-between items-center">
                <GraphPicker
                  {...{
                    graph,
                    loading: loadingGraph,
                    title: answer?.step_id || "Pick step id",
                    onPick: (id) =>
                      setQuestion((p) => {
                        p.answers![i].step_id = id;
                      }),
                  }}
                />
                <If cond={!!answer?.step_id}>
                  <div
                    className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
                    onClick={() =>
                      setQuestion((p) => {
                        if (answer?.step_id) {
                          delete p.answers![i].step_id;
                        }
                      })
                    }
                  >
                    <AiOutlineClose color="red" />
                  </div>
                </If>
              </div>
              <Accordion
                title={`Choose answer ${i + 1} image:`}
                titleClass="font-heebo"
                childrenContainerClass="max-w-[200px] overflow-hidden"
              >
                <div>
                  {Object.keys(HEADER_PICS).map((pic) => (
                    <div
                      className={`
              w-fit
              p-2 m-2 hover:bg-slate-300 cursor-pointer mt-4
              ${
                question.answers![i].image === pic && "bg-slate-700 text-white"
              }`}
                      onClick={() =>
                        setQuestion((p) => {
                          if (p.answers![i].image === pic) {
                            p.answers![i].image = undefined;
                          } else {
                            p.answers![i].image = pic;
                          }
                        })
                      }
                      key={pic}
                    >
                      {pic}
                    </div>
                  ))}
                </div>
              </Accordion>
            </If>
          </div>

          <div
            className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
            onClick={() => onRemove(i)}
          >
            <AiOutlineClose color="red" />
          </div>
        </div>
      ))}
    </DashboardFormWrapper>
  );
};

export default MultiChoiceFrom;
