import React from "react";
import { AiOutlineLeft } from "react-icons/ai";

interface Props {
  onClick: () => void;
}
const BackButton = ({ onClick }: Props) => {
  return (
    <AiOutlineLeft
      onClick={onClick}
      className="
    cursor-pointer
    text-white
     left-[18px] top-[15px] absolute"
      size={27}
    />
  );
};

export default BackButton;
