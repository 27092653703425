import { ScaleLoader } from "react-spinners";
import { BG, PRIMARY_PURPLE } from "utils";

const AppLoader = () => {
  return (
    <div
      style={{
        background: BG,
      }}
      className="flex flex-1 h-screen w-screen justify-center items-center"
    >
      <ScaleLoader color={PRIMARY_PURPLE} width={8} height={70} />
    </div>
  );
};

export default AppLoader;
