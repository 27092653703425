import { useEffect, useState } from "react";

interface Props {
  children: JSX.Element[] | JSX.Element;
  cond: boolean;
  delay?: number;
}

const If = ({ children, cond, delay = 0 }: Props) => {
  const [show, setShow] = useState(cond);
  useEffect(() => {
    const t = setTimeout(() => {
      setShow(cond);
    }, delay);
    return () => {
      clearTimeout(t);
      setShow(cond);
    };
  }, [cond, delay]);
  if (!show) return <></>;
  return <>{children}</>;
};

export default If;
