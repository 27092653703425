import skyThumbnail from "assets/svgs/headerSvgs/skyThumbnail.svg";
import heartThumbnail from "assets/svgs/headerSvgs/heartThumbnail.svg";
import journey1 from "assets/svgs/headerSvgs/journey1.svg";
import heartCover from "assets/svgs/headerSvgs/heartCover.svg";
import changeYourDiet from "assets/svgs/headerSvgs/Change_your_diet.svg";
import conversationWithMyDoctor from "assets/svgs/headerSvgs/Conversation_with_my_doctor.svg";
import createContent from "assets/svgs/headerSvgs/createContent.svg";
import digitalPresence from "assets/svgs/headerSvgs/digitalPresence.svg";
import fearOfDeath from "assets/svgs/headerSvgs/fearOfDeath.svg";
import finance from "assets/svgs/headerSvgs/finance.svg";
import findingMeaning from "assets/svgs/headerSvgs/findingMeaning.svg";
import hallOfFame from "assets/svgs/headerSvgs/hallOfFame.svg";
import myWishList from "assets/svgs/headerSvgs/myWishList.svg";
import nonMedicationSymptomsManagement from "assets/svgs/headerSvgs/nonMedicationSymptomsManagement.svg";
import relationship from "assets/svgs/headerSvgs/relationship.svg";
import sharingWithFamilyAndFriends from "assets/svgs/headerSvgs/sharingWithFamilyAndFriends.svg";
import symptomManagement from "assets/svgs/headerSvgs/symptomManagement.svg";
import will from "assets/svgs/headerSvgs/will.svg";
import wishesForMedicalCare from "assets/svgs/headerSvgs/wishesForMedicalCare.svg";

export const HEADER_PICS = {
  skyThumbnail,
  journey1,
  heartThumbnail,
  heartCover,
  changeYourDiet,
  conversationWithMyDoctor,
  createContent,
  digitalPresence,
  fearOfDeath,
  finance,
  findingMeaning,
  hallOfFame,
  myWishList,
  nonMedicationSymptomsManagement,
  relationship,
  sharingWithFamilyAndFriends,
  symptomManagement,
  will,
  wishesForMedicalCare,
};
export const DEFAULT_TEXT = "Wishes for medical care";
