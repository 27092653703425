import React from "react";
import { useNavigate } from "react-router-dom";
import { Question } from "types";
import QuestionsViewer from "./QuestionsViewer";

interface Props {
  scrollRef: React.Ref<HTMLDivElement>;
  question: Partial<Question>;
  loading: boolean;
  headerClassName?: string;
  fromDashboard?: boolean;
}

const DashboardQuestionViewer = ({
  scrollRef,
  question,
  loading,
  headerClassName,
  fromDashboard = false,
}: Props) => {
  const navigate = useNavigate();

  return (
    <QuestionsViewer
      {...{ loading, question, scrollRef, headerClassName, fromDashboard }}
      containerClassName="h-full overflow-scroll w-full pb-32"
      onAnswer={() => {}}
      onHeaderBackButtonPress={() => navigate(-1)}
    />
  );
};

export default DashboardQuestionViewer;
