import BackButton from "../BackButton";
import Logo from "../Logo";

interface Props {
  onBackButtonPress: () => void;
  headerClassName?: string;
}

const Header = ({ onBackButtonPress, headerClassName = "" }: Props) => {
  return (
    <div
      className={`flex fixed top-0 w-full
     ${headerClassName}  flex-col z-50`}
    >
      <div className="bg-primary-blue h-14 flex ">
        <BackButton onClick={onBackButtonPress} />
        <div className="flex-1 text-white">
          <div className="flex justify-center items-center h-full text-3xl">
            <Logo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
