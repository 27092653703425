import time from "assets/svgs/time.svg";
import sunset from "assets/svgs/sunset.svg";
import prop from "assets/svgs/prop.svg";
import cloudD from "assets/svgs/cloudD.svg";
import heart from "assets/svgs/heart.svg";
import cup from "assets/svgs/cup.svg";
import bannerD from "assets/svgs/bannerD.svg";
import bird from "assets/svgs/bird.svg";
import hangedMoon from "assets/svgs/hanged_moon.svg";

export const QUESTIONS_PICS = {
  time,
  sunset,
  prop,
  cloudD,
  heart,
  cup,
  bannerD,
  bird,
  hangedMoon,
};
