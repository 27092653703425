import { ReactNode } from "react";
import RectangleButton from "../RectangleButton";

interface Props {
  onAdd: () => void;
  children: ReactNode | ReactNode[];
  title: string;
}
export const DashboardFormWrapper = ({ onAdd, children, title }: Props) => {
  return (
    <div className="mt-20 text-primary-blue">
      <div className="flex flex-col">{children}</div>
      <RectangleButton
        onClick={onAdd}
        title={`Add ${title}`}
        className="w-fit mx-auto my-10"
      />
    </div>
  );
};
